import React from 'react'
import { Layout, Menu} from 'antd';
import { Location } from '@reach/router'
import Link from '../Link'
const { Header } = Layout;

class Head extends React.Component {


    render(){

        return (
            <Header>
                <div className="logo"/>
                <Location>
                    {({ location }) => {
                        return <Menu
                            theme="dark"
                            mode="horizontal"
                            defaultSelectedKeys={['/']}
                            selectedKeys={[location.pathname]}
                            style={{lineHeight: '64px'}}
                        >
                            <Menu.Item key={['/']}><Link to="/">Home</Link></Menu.Item>
                            <Menu.Item key={['/create']}><Link to="/create">Create Campaign</Link></Menu.Item>
                            <Menu.Item key={['/calendar']}><Link to="/calendar">Campaign Calendar</Link></Menu.Item>
                        </Menu>
                    }}
                </Location>
            </Header>
        );
    }
}

export default Head
