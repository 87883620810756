import React from 'react';
import Wrapper from '../components/Wrapper'
import { Row, Col, Calendar, Badge, Icon} from 'antd';

import './calendar.less'

export default class CalendarPage extends React.Component {

    constructor(props) {
        super(props);
        this.dateCellRender = this.dateCellRender.bind(this);
        this.monthCellRender = this.monthCellRender.bind(this);
    }

    getListData(value) {
        let listData;
        switch (value.date()) {
            case 8:
                listData = [
                    { type: 'mail', content: 'ABO Valentines Email 1' },
                    { type: 'mail', content: 'BRN Valentines Email 1' },
                    { type: 'mail', content: 'CAS Valentines Email 1' },
                    { type: 'mobile', content: 'CAS Valentines Push' },
                ]; break;
            case 10:
                listData = [
                    { type: 'mail', content: 'ONL St Pats teaser - Food' },
                    { type: 'mail', content: 'ONL St Pats teaser - Drink' },
                    { type: 'team', content: 'CAS Valentines Social post' },
                ]; break;
            case 15:
                listData = [
                    { type: 'team', content: 'CAS Valentines follow up post' },
                    { type: 'mobile', content: 'TOB Push notification' },
                ]; break;
            default:
        }
        return listData || [];
    }

    getMonthData(value) {
        if (value.month() === 1) {
            return 27;
        }
        if (value.month() === 2) {
            return 33;
        }
        if (value.month() === 3) {
            return 24;
        }
        if (value.month() === 4) {
            return 14;
        }
    }

    monthCellRender(value) {
        const num = this.getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Events</span>
            </div>
        ) : null;
    }

    dateCellRender(value) {
        const listData = this.getListData(value);
        return (
            <ul className="events">
                {
                    listData.map(item => (
                        <li key={item.content}>
                            <Badge text={item.content}>
                                <Icon type={item.type} title={item.content} /> {item.content}
                            </Badge>
                        </li>
                    ))
                }
            </ul>
        );
    }

    render() {

        return (
            <Wrapper>
                <Row className="stepWrapper">
                    <Col span={12} offset={6}>
                        <h1>Campaign Calendar</h1>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} offset={0} className="calWrapper">
                        <Calendar dateCellRender={this.dateCellRender} monthCellRender={this.monthCellRender}  />
                    </Col>
                </Row>

            </Wrapper>
        );
    }
}

