import React from 'react'
import Head from './Head'
import Foot from './Foot'

import './wrapper.less'
import { Layout } from 'antd';

const Wrapper = ({children}) => (
    <div className="app">
        <Layout className="layout">
            <Head />

            <div className="mainContent">
                {children}
            </div>

            <Foot />
        </Layout>
    </div>
);

export default Wrapper
