import React from 'react'
import { Layout } from 'antd';

const { Footer } = Layout;

class Foot extends React.Component {
    render(){

        return (
            <Footer style={{ textAlign: 'center' }}>
                © 2019 Campaign Tool
            </Footer>
        );
    }
}

export default Foot
